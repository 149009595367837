@import '~antd/dist/antd.css';

.d-flex {
  display: flex!important;
}
.flex-column {
  flex-direction: column!important;
}
.align-items-center {
  align-items: center;
}
.h-100 {
  min-height: 100vh!important;
}
.w-100 {
  width: 100%!important;
}
.m-0 {
  margin: 0!important;
}
.p-0 {
  padding: 0!important;
}
.ml-1 {
  margin-left: 10px;
}
.text-right {
  text-align: right!important;
}
.text-center {
  text-align: center!important;
}
img {
  max-width: 100%;
}
.App {
  max-width: 1232px;
  margin: 0 auto auto;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 50px;
  width: 100%;
}

.title-header-pages {
  font-weight: 700!important;
  font-size: 32px!important;
  padding: 5px 0;
  margin: 0!important;
  letter-spacing: -1px;
}

.txt-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  display: block;
}

a{
  color: #141414;
}
 a:hover {
  color: #CDA349;
}

.ant-typography a {
  color: #141414;
}
.ant-typography a:hover {
  color: #CDA349;
}

/* custom menu */
.ant-menu-item-selected a, .ant-menu-item-selected a:hover, .ant-menu-item-active a,
.ant-menu-item a:hover,
.ant-menu-title-content a:hover,
.ant-menu-item a:hover,
.ant-menu-item-active,
.ant-menu-submenu:hover,
.ant-menu-submenu-title:hover,
.ant-menu-submenu-open,
.ant-menu-item-selected,
.ant-menu-submenu-selected{
  color: #CDA349 !important;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: transparent !important;
}
.ant-menu-item:active, .ant-menu-submenu-title:active{
  background: transparent !important;
}


/* style tabs */

.ant-tabs-tab:first-child {
  margin-left: 0px!important;
}
.ant-tabs-tab .ant-tabs-tab-btn {
    transition: 0s!important;
}

.ant-tabs-tab-active {
  transition: 0s!important;
}

.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab, .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
  transition: 0s!important;
  border-radius: 0 !important;
}

.ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  display: none;
}
.ant-table-container table > thead > tr:first-child th:last-child {
  border-top-right-radius: 0 !important;
}

.success:before{
  content: '';
  width: 8px;
  height: 8px;
  background-color: #00E8B4;
  display: inline-block;
  margin-right: 10px;
}
.error:before{
  content: '';
  width: 8px;
  height: 8px;
  background-color: #c34040;
  display: inline-block;
  margin-right: 10px;
}

.ant-tooltip-inner {
  border-radius: 0;
}


/* link hover */
.link-hover{
  color: #000;

}
.link-hover:hover{
  color: #CDA349 !important;
}


/* table custom */
.ant-table-thead .ant-table-cell{
  font-size: 12px !important;
  text-transform: uppercase;
}

/* input style */
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled){
  border-color: transparent !important;
  box-shadow: none !important;
}
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover{
  border-color: transparent !important;
  box-shadow: none !important;
}
.ant-input:hover{
  border-color: transparent !important;
}


/* button */
button.button-custom{
  cursor: pointer;
  color: #fff;
  background-color: #766B60;
  font-size: 14px;
  height: 32px;
  box-shadow: none !important;
  outline: none;
  padding: 4px 16px;
  border-color:transparent;
}
button.button-custom:hover, button.button-custom:active{
  outline: none;
  border-color:transparent;
  box-shadow: none !important;
}